import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Calling an HTTP service";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "calling-an-http-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#calling-an-http-service",
        "aria-label": "calling an http service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Calling an HTTP service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#decorating-a-webclient"
        }}>{`Decorating a WebClient`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#service-discovery-with-webclient"
        }}>{`Service discovery with WebClient`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-clientfactory"
        }}>{`Configuring ClientFactory`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#see-also"
        }}>{`See also`}</a></li>
    </ul>
    <p>{`You can create a `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{` with a base URI and send a request with a relative path.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.WebClient;
import com.linecorp.armeria.common.AggregatedHttpResponse;
import com.linecorp.armeria.common.HttpHeaderNames;
import com.linecorp.armeria.common.HttpMethod;
import com.linecorp.armeria.common.RequestHeaders;

WebClient webClient = WebClient.of("http://example.com/");

// Send a simple GET request.
AggregatedHttpResponse res1 = webClient.get("/foo/bar.txt").aggregate().join();

// Send a GET request with an additional header.
RequestHeaders getJson = RequestHeaders.of(HttpMethod.GET, "/foo/bar.json",
                                           HttpHeaderNames.ACCEPT, "application/json");

AggregatedHttpResponse res2 = webClient.execute(getJson).aggregate().join();

// Send a simple POST request encoded in UTF-8.
AggregatedHttpResponse res3 = webClient.post("/upload", "{ \\"foo\\": \\"bar\\" }")
                                       .aggregate().join();
`}</code></pre>
    <p>{`You can also create a `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{` without a base URI and send a request with an absolute URI.
The ad-hoc `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{` would be useful in the following cases:`}</p>
    <ul>
      <li parentName="ul">{`Sending requests to arbitrary endpoints`}</li>
      <li parentName="ul">{`Building a proxy server`}</li>
      <li parentName="ul">{`Handling redirected requests`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpStatus;
import com.linecorp.armeria.common.HttpHeaderNames;

// Create a WebClient without a base URI.
WebClient webClient = WebClient.of();

// Send a request with an absolute URI.
AggregatedHttpResponse res1 = webClient.get("http://example.com/foo/bar.txt")
                                       .aggregate()
                                       .join();

// Send a GET request with an authority header.
HttpRequest request = HttpRequest.of(RequestHeaders.builder()
                                                   .scheme(SessionProtocol.HTTP)
                                                   .authority("example.com")
                                                   .method(HttpMethod.GET)
                                                   .path("/foo/bar.txt")
                                                   .build());
AggregatedHttpResponse res2 = webClient.execute(request).aggregate().join();

// Handle a redirected request
AggregatedHttpResponse redirected = webClient.get("http://example.com/redirect")
                                             .aggregate()
                                             .join();
assert redirected.status() == HttpStatus.TEMPORARY_REDIRECT;
String location = redirected.headers().get(HttpHeaderNames.LOCATION);
AggregatedHttpResponse actual = webClient.get(location).aggregate().join();
`}</code></pre>
    <h2 {...{
      "id": "decorating-a-webclient",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#decorating-a-webclient",
        "aria-label": "decorating a webclient permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decorating a WebClient`}</h2>
    <p>{`You can enrich your `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{` by decorating it with decorators.
A decorator wraps another client to intercept an outgoing request or an incoming response.
A lot of core features such as logging, metrics and distributed tracing are implemented as decorators.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://BraveClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/brave/BraveClient.html"
        }}>{`type://BraveClient`}</a>{` which `}<a parentName="li" {...{
          "href": "/docs/advanced-zipkin"
        }}>{`traces`}</a>{` outbound requests using
`}<a parentName="li" {...{
          "href": "https://github.com/openzipkin/brave"
        }}>{`Brave`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
        }}>{`type://CircuitBreakerClient`}</a>{` which `}<a parentName="li" {...{
          "href": "/docs/client-circuit-breaker"
        }}>{`opens a circuit`}</a>{` on failed responses.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CookieClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/cookie/CookieClient.html"
        }}>{`type://CookieClient`}</a>{` which stores cookies of responses using `}<a parentName="li" {...{
          "href": "type://CookieJar:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/cookie/CookieJar.html"
        }}>{`type://CookieJar`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ConcurrencyLimitingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/limit/ConcurrencyLimitingClient.html"
        }}>{`type://ConcurrencyLimitingClient`}</a>{` which limits the concurrent number of active requests.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ContentPreviewingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/ContentPreviewingClient.html"
        }}>{`type://ContentPreviewingClient`}</a>{` which
`}<a parentName="li" {...{
          "href": "/docs/advanced-structured-logging/#enabling-content-previews"
        }}>{`previews the content`}</a>{` of
requests and responses.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
        }}>{`type://DecodingClient`}</a>{` which decompresses a
`}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Encoding"
        }}>{`compressed`}</a>{` response.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://MetricCollectingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/metric/MetricCollectingClient.html"
        }}>{`type://MetricCollectingClient`}</a>{` which
`}<a parentName="li" {...{
          "href": "/docs/advanced-metrics#collecting-client-side-metrics"
        }}>{`collects metrics`}</a>{` using
`}<a parentName="li" {...{
          "href": "https://micrometer.io/"
        }}>{`Micrometer`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
        }}>{`type://LoggingClient`}</a>{` which logs requests and responses.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{` which `}<a parentName="li" {...{
          "href": "/docs/client-retry"
        }}>{`automatically retries`}</a>{` failed requests.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import brave.http.HttpTracing;
import com.linecorp.armeria.client.brave.BraveClient;
import com.linecorp.armeria.client.circuitbreaker.CircuitBreaker;
import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerClient;
import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerRule;
import com.linecorp.armeria.client.cookie.CookieClient;
import com.linecorp.armeria.client.encoding.DecodingClient;
import com.linecorp.armeria.client.limit.ConcurrencyLimitingClient;
import com.linecorp.armeria.client.logging.ContentPreviewingClient;
import com.linecorp.armeria.client.logging.LoggingClient;
import com.linecorp.armeria.client.metric.MetricCollectingClient;
import com.linecorp.armeria.client.retry.RetryRule;
import com.linecorp.armeria.client.retry.RetryingClient;
import com.linecorp.armeria.common.metric.MeterIdPrefixFunction;

HttpTracing tracing = ...;
WebClient.builder()
         .decorator(BraveClient.newDecorator(tracing))
         .decorator(CircuitBreakerClient.newDecorator(
             CircuitBreaker.ofDefaultName(), CircuitBreakerRule.onServerErrorStatus()))
         .decorator(ConcurrencyLimitingClient.newDecorator(64))
         .decorator(ContentPreviewingClient.newDecorator(128))
         .decorator(CookieClient.newDecorator())
         .decorator(DecodingClient.newDecorator())
         .decorator(MetricCollectingClient.newDecorator(
             MeterIdPrefixFunction.ofDefault("armeria.client")))
         .decorator(LoggingClient.newDecorator())
         .decorator(RetryingClient.newDecorator(RetryRule.onUnprocessed()))
         ...
         .build();
`}</code></pre>
    <p>{`Please see `}<a parentName="p" {...{
        "href": "/docs/client-decorator"
      }}>{`Decorating a client`}</a>{` to learn how it works.`}</p>
    <h2 {...{
      "id": "service-discovery-with-webclient",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#service-discovery-with-webclient",
        "aria-label": "service discovery with webclient permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Service discovery with WebClient`}</h2>
    <p>{`Armeria provides the various `}<a parentName="p" {...{
        "href": "https://microservices.io/patterns/client-side-discovery.html"
      }}>{`service discovery`}</a>{`
mechanisms with `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{`,
from Kubernetes-style `}<a parentName="p" {...{
        "href": "/docs/client-service-discovery#dns-based-service-discovery-with-dnsendpointgroup"
      }}>{`DNS records`}</a>{`
to `}<a parentName="p" {...{
        "href": "/docs/client-service-discovery#consul-based-service-discovery-with-consulendpointgroup"
      }}>{`Consul`}</a>{`.
Requests are sent to dynamically retrieved `}<a parentName="p" {...{
        "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`typeplural://Endpoint`}</a>{` by specifying an `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{`
to a `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.endpoint.dns.DnsServiceEndpointGroup;

// Retrieve the Endpoint list from SRV records
DnsServiceEndpointGroup group =
    DnsServiceEndpointGroup.of("k8s.default.svc.cluster.local.");
    
// Filter out unhealthy endpoints
HealthCheckedEndpointGroup healthCheckedGroup =
        HealthCheckedEndpointGroup.of(group, "/monitor/l7check");
        
WebClient.builder(SessionProtocol.HTTP, healthCheckedGroup)
         ...
         .build();
`}</code></pre>
    <p>{`Please check `}<a parentName="p" {...{
        "href": "/docs/client-service-discovery"
      }}>{`Client-side load balancing and service discovery`}</a>{`
for more information.`}</p>
    <h2 {...{
      "id": "configuring-clientfactory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-clientfactory",
        "aria-label": "configuring clientfactory permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring ClientFactory`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` manages connections and protocol-specific properties.
You can build your own `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` using `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html"
      }}>{`type://ClientFactoryBuilder`}</a>{` and
set it through `}<a parentName="p" {...{
        "href": "type://WebClientBuilder#factory()"
      }}>{`type://WebClientBuilder#factory()`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ClientFactory;
// Create a customized ClientFactory
ClientFactory clientFactory =
    ClientFactory.builder()
                 // Increase the connect timeout from 3.2s to 10s.
                 .connectTimeout(Duration.ofSeconds(10))
                 .
                 .build();

WebClient client =
        WebClient.builder()
                 .factory(clientFactory)
                 .build();
`}</code></pre>
    <p>{`Please check `}<a parentName="p" {...{
        "href": "/docs/client-factory"
      }}>{`Customizing a `}<inlineCode parentName="a">{`ClientFactory`}</inlineCode>{` with `}<inlineCode parentName="a">{`ClientFactoryBuilder`}</inlineCode></a>{`
for the details.`}</p>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/client-retrofit"
        }}>{`Retrofit integration`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      